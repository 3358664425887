import React from 'react'
import classNames from 'classnames'

export default function RadioGroup({ items, name, className, onChange, defaultValue }) {
    const DEFAULT = 'my-4';

    return (
        <div className={classNames(DEFAULT, className)}>
            {items.map((item, i) => (
                <label
                    key={i}
                    htmlFor={item.id}
                    className="flex active:bg-red-600 items-center py-1"
                >
                    <input
                        type="radio"
                        name={name}
                        defaultChecked={defaultValue === item.value}
                        value={item.value}
                        id={item.id}
                        onChange={onChange} />
                    <p className="pl-2 text-base">{item.label}</p>
                </label>
            ))}
        </div>
    )
}
