import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation, Trans } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import TeacherRegisterForm from 'src/components/register/TeacherRegisterForm'
import heroRegisterTeacher from 'src/images/register/hero-register-teacher.jpg'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function RegisterTeacher(props) {
    const { t } = useTranslation();
    const { setTheme } = useContext(ThemeContext);
    const { registerStudent } = useLocalizedRoutes();

    useEffect(() => setTheme('normal'), []);

    return (
        <VisitorLayout {...props} hero={heroRegisterTeacher} title={t('teacher.register.title')}>
            <div className="px-6 lg:px-0">
                <Heading type='sub-title'>{t('teacher.register.title')}</Heading>
                <p className="mt-4">
                    <Trans i18nKey="teacher.register.intro">
                        Register as a student? <Link to={registerStudent} className="text-dark-blue-600 underline">Click here</Link>
                    </Trans>
                </p>
            </div>
            <TeacherRegisterForm />
        </VisitorLayout>
    )
}
