import React, { useState, useContext } from 'react'
import { navigate, Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useTranslation, Trans } from 'react-i18next'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import usePreferences from 'src/hooks/usePreferences'
import Input, { DateInput } from 'src/components/forms/Input'
import Checkbox from 'src/components/forms/Checkbox'
import Select from 'src/components/forms/Select'
import ImageUpload from 'src/components/forms/ImageUpload'
import RadioGroup from 'src/components/forms/RadioGroup'
import Label from 'src/components/forms/Label'
import { SubmitButton } from 'src/components/buttons/Primary'
import { languages } from 'src/tempData'
import { countries } from 'src/util/countries'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { isValidVAT } from 'src/util'
import { Error } from 'src/components/forms'
import { fromDateFormat } from '../../api';

export default function TeacherRegisterForm() {
    const { t, i18n } = useTranslation();
    const { dateFormat } = usePreferences();
    const { setOAuthTokens, state: {profile} } = useContext(ProfileContext);
    const { convertToDateFormat, registerTeacher, setUser, editRegisterTeacher } = useVisitorApi();
    const { confirmEmailTeacher, verifyEmailTeacher, privacyPolicy } = useLocalizedRoutes();
    const [defaultCountry, setDefaultCountry] = useState(profile ? countries.find(country => country.value === profile.country_code) : '');
    const [defaultLocale, setDefaultLocale] = useState(profile ? languages.find(locale => locale.value === profile.locale) : '');
    const [statute, setStatute] = useState(null);
    const [error, setError] = useState(false);
    const baseUrl = process.env.API_BASE_URL;
    const radioOptions = [
        {
            id: 1,
            value: 'invoice',
            label: t('teacher.register.paymentOptions.option1')
        },
        {
            id: 2,
            value: 'performance_note',
            label: t('teacher.register.paymentOptions.option2')
        },
        /*{
            id: 3,
            value: 'interim',
            label: t('teacher.register.paymentOptions.option3')
        }*/
    ];
    const initialValues = {
        profilePicture: profile ? profile.teacher_profile?.profile_picture_url : '',
        firstName: profile ? profile.first_name : '',
        lastName: profile ? profile.last_name : '',
        email: profile ? profile.email : '',
        phone: profile ? profile.phone : '',
        dateOfBirth: profile && profile.date_of_birth ? fromDateFormat(profile.date_of_birth).toFormat(dateFormat) : '',
        countryCode: profile ? profile.country_code : '',
        postalCode: profile ? profile.postal_code : '',
        locale: profile ? profile.locale : '',
        password: '',
        passwordConfirmation: '',
        paymentMethod: profile ? profile.teacher_profile?.payment_method : '',
        vat: profile ? profile.teacher_profile?.vat : '',
        agreeToTerms: false,
        agreeToPrivacyPolicy: false,
    };
    const {
        values,
        setValue,
        onChangeValue,
        onChangeValueByName,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });
    const COL = 'px-4 w-full lg:w-6/12 mt-4';
    const COL_FULL = 'px-4 w-full mt-6';
    const location = useLocation();

    async function onSubmit(values) {
        if (profile) {
            values.locale = profile.locale;
            delete values.profile_picture;
            return await editRegisterTeacher({
                ...values,
                id: profile.id,
                date_of_birth: convertToDateFormat(values.dateOfBirth, dateFormat),
                redirectUrl: location.origin + verifyEmailTeacher
            });
        }

        return await registerTeacher({
            ...values,
            dateOfBirth: convertToDateFormat(values.dateOfBirth, dateFormat),
            redirectUrl: location.origin + verifyEmailTeacher
        });
    }

    function validateVAT() {
        if(values.vat && !isValidVAT(values.vat)) {
            return setError(true);
        }

        return onSubmit()
    }

    function onRegistrationCompleted({ accessToken, refreshToken }) {
        setOAuthTokens({ accessToken, refreshToken });
        setUser(accessToken)
        navigate(confirmEmailTeacher, { state: { values } });
    }

    function isFacebookApp() {
        if (typeof window === 'undefined') {
            return false;
        }

        var ua = window.navigator.userAgent || window.navigator.vendor || window.opera;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    }

    return isFacebookApp() ? (
        <div className="w-full md:w-25 px-6 lg:px-0 mt-4 md:mb-8 lg:my-10">
            <div className="py-4 mb-4 border-t border-b border-error">
                <h1 className="font-bold">{t('register.facebookInApp.title')}</h1>
                <p>{t('register.facebookInApp.text')}</p>
            </div>
            <div className="text-right">
                <p>{t('register.facebookInApp.howto')}</p>
            </div>
        </div>
    ) : (
        <form
            className="w-full md:w-25 px-6 lg:px-0 mt-4 md:mb-8 lg:my-10"
            onSubmit={submit}
        >
            <div className="flex flex-wrap items-start -mx-4">
                <div className={COL_FULL}>
                    <ImageUpload
                        id="profilePicture"
                        name="profilePicture"
                        error={errors.profilePicture}
                        label={t('general.forms.chooseProfilePic')}
                        handleChange={(e) => setValue('profilePicture', e)}
                        className="mb-4"
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="firstName"
                        value={values.firstName}
                        error={errors.firstName}
                        label={t('general.forms.firstName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="lastName"
                        value={values.lastName}
                        error={errors.lastName}
                        label={t('general.forms.lastName')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="email"
                        value={values.email}
                        error={errors.email}
                        label={t('general.forms.email')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="phone"
                        value={values.phone}
                        error={errors.phone}
                        label={t('general.forms.phone')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <DateInput
                        isRequired
                        name="dateOfBirth"
                        value={values.dateOfBirth}
                        error={errors.dateOfBirth}
                        label={t('general.forms.dateOfBirth')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        isRequired
                        isFormSelect
                        id="countryCode"
                        name="countryCode"
                        defaultValue={defaultCountry}
                        data={countries}
                        error={errors.countryCode}
                        label={t('general.forms.country')}
                        placeholder={t('general.forms.countryPlaceholder')}
                        onChange={(item) => setValue('countryCode', item.value)}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        name="postalCode"
                        value={values.postalCode}
                        error={errors.postalCode}
                        label={t('general.forms.postalCode')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Select
                        isRequired
                        isFormSelect
                        id="locale"
                        name="locale"
                        defaultValue={defaultLocale}
                        data={languages}
                        error={errors.locale}
                        label={t('general.forms.language')}
                        placeholder={t('general.forms.languagePlaceholder')}
                        onChange={(lang) => setValue('locale', lang.value)}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        type="password"
                        name="password"
                        value={values.password}
                        error={errors.password}
                        label={t('general.forms.password')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL}>
                    <Input
                        isRequired
                        type="password"
                        name="passwordConfirmation"
                        value={values.passwordConfirmation}
                        error={errors.passwordConfirmation}
                        label={t('general.forms.passwordConfirmation')}
                        onChange={onChangeValue}
                    />
                </div>
                <div className={COL_FULL}>
                    <Label>{t('teacher.register.paymentOptions.title')}</Label>
                    <RadioGroup
                        defaultChecked={profile ? profile.teacher_profile?.payment_method : null}
                        name="paymentMethod"
                        items={radioOptions}
                        onChange={e => {
                            onChangeValue(e);
                            setStatute(e.target.value);
                        }}
                    />
                </div>
                {statute === 'invoice' &&
                    <div className={COL}>
                        <Input
                            isRequired
                            name="vat"
                            value={values.vat}
                            error={errors.vat}
                            label={t('general.forms.vat')}
                            onChange={onChangeValue}
                        />
                        { error && <Error>Please enter a valid VAT</Error>}
                    </div>
                }
                <div className={COL_FULL}>
                    <Checkbox
                        isRequired
                        label={
                            <Trans i18nKey="general.forms.agreeToTerms">
                                I agree with the <a href={`${baseUrl}/Whambam-Terms&Conditions-${i18n.language}.pdf`} className="text-dark-blue-600 underline">Terms & Conditions</a>
                            </Trans>
                        }
                        isChecked={values.agreeToTerms}
                        error={errors.agreeToTerms}
                        onChange={onChangeValueByName('agreeToTerms')}
                    />
                    <Checkbox
                        isRequired
                        label={
                            <Trans i18nKey="general.forms.agreeToPrivacy">
                                I agree with the <a href={privacyPolicy} className="text-dark-blue-600 underline">Privacy Policy</a>
                            </Trans>
                        }
                        isChecked={values.agreeToPrivacyPolicy}
                        error={errors.agreeToPrivacyPolicy}
                        onChange={onChangeValueByName('agreeToPrivacyPolicy')}
                    />
                </div>
                <div className={COL_FULL}>
                    <SubmitButton
                        isLoading={isLoading}
                    >
                        {t('student.register.submit')}
                    </SubmitButton>
                </div>
            </div>
        </form>
    )
}
