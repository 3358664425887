import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {Error} from 'src/components/forms'

export default function ImageUpload({
                                        id,
                                        name,
                                        image,
                                        className,
                                        label,
                                        handleChange,
                                        error,
                                    }) {
    const [thumbnail, setThumbnail] = useState(image);
    const { t } = useTranslation();
    const [frontEndError, setFrontEndError] = useState(false)

    function handleUpload(event) {
        setThumbnail(URL.createObjectURL(event.target.files[0]));
        handleChange(event.target.files[0]);

        if (event.target.files[0].size > 5000000) {
            setFrontEndError(true)
        } else {
            setFrontEndError(false)
        }
    }

    function renderError() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });

        if (!error && frontEndError) {
            return t('teacher.register.imageErrorSize')
        }

        if (error && error[0] === "The profile picture has invalid image dimensions.") {
            return t('teacher.register.imageErrorDimensions')
        } else if (error && error[0] === "The profile picture field is required.") {
            return t('teacher.register.imageErrorRequired')
        } else if (error && error[0] === "The profile picture may not be greater than 5000 kilobytes.") {
            return t('teacher.register.imageErrorSize')
        } else if (error) return error
        return null
    }

    return (
        <label htmlFor={id} className={classNames('flex items-center cursor-pointer', className)}>
            <div className="w-16 h-16 rounded-full bg-grey-300 mr-4 overflow-hidden">
                {thumbnail &&
                <img
                    src={thumbnail}
                    className="block w-full h-full object-cover"
                />
                }
            </div>
            <div className="flex flex-col">
                <p>{label}</p>
                {(error || frontEndError) && <Error>{renderError()}</Error>}
            </div>

            <input
                id={id}
                name={name}
                type="file"
                className="absolute top-0 left-0 hidden"
                onChange={handleUpload}
            />
        </label>
    )
}
