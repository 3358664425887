import React from 'react'
import { Link } from 'gatsby'
import Meta from 'src/components/layout/Meta'
import Logo from 'src/components/icons/Logo'
import 'src/components/layout/VisitorLayout.css'

export default function VisitorLayout({ pageContext, children, hero, title }) {

    return (
        <div>
            <Meta title={title} />
            <div className="relative">
                <div className="container mx-auto">
                    <div className="flex items-stretch flex-wrap">
                        <div className="w-full lg:w-4/12 lg:h-screen">
                            <div
                                className="px-4 pb-4 pt-8 lg:p-10 bg-cover bg-center bg-dark-blue-600 hero-register"
                                style={{backgroundImage: `url(${hero})`}}
                            >
                                <Link
                                    to="/"
                                    className="inline-block"
                                >
                                    <Logo />
                                </Link>
                            </div>
                        </div>
                        <div className="lg:w-1/12" />
                        <div className="w-full lg:w-6/12 py-12">
                            {children}
                        </div>
                        <div className="lg:w-1/12" />
                    </div>
                </div>
            </div>
        </div>
    )
}

