import React, { useState } from 'react'
import { Error } from 'src/components/forms'

const DEFAULT = 'flex items-center font-normal text-dark text-left mb-3 cursor-pointer';

export default function Checkbox({ name, label, error, isChecked, onChange, isRequired }) {
    const [checked, setChecked] = useState(isChecked)

    function handleChange(e) {
        setChecked(!checked);
        onChange(e.target.checked);
    }

    return (
        <>
            <label htmlFor={name} className={DEFAULT}>
                <input
                    required={isRequired}
                    type="checkbox"
                    name={name}
                    onChange={handleChange}
                />
                <span className="pl-2">
                    {label}
                </span>
            </label>
            {error &&
                <div className="mb-3">
                    <Error>{error}</Error>
                </div>
            }
        </>
    );
}

Checkbox.defaultProps = {
    error: undefined,
};
